import amsterdamTheme from 'gatsby-theme-amsterdam/src/gatsby-plugin-theme-ui'

export default {
  ...amsterdamTheme,
  colors: {
    ...amsterdamTheme.colors,
    text: '#58595c',
    background: '#f6f5f4',
  },
  fonts: {
    ...amsterdamTheme.fonts,
    body: "'Karla', 'Arial', sans-serif",
    heading: "'Marcellus', 'Arial', sans-serif",
  },
}